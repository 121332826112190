<template>
           <v-row>
             <v-col cols="12" v-for="(item,index) in inbox" :key="index">
               <ListCard :item="item"/>
             </v-col>
           </v-row>
</template>

<script>
import ListCard from "@/components/inbox/ListCard";
export default {
  components: {
    ListCard,
  },
    props: {
        inbox: {
            type: Array,
            default: function (){ return []}
        },
    },
    data() {
        return {
            model: null
        }
    }
}
</script>

<style>

</style>